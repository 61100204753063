<template>
  <div class="container detail">
    <div class="detail-header">
      <div class="title">
        {{detailData.title}}
      </div>
      <div v-if="detailData.create_at" class="date">{{detailData.create_at.substring(0, 10)}}</div>
    </div>
    <div class="detail-content">
      <p v-html="detailData[`content_${$i18n.locale}`]"></p>
    </div>
  </div>
</template>

<script>
import request from "../../api/request";
export default {
  name:"detail",
  data(){
    return{
      id:this.$route.params.id,
      staticUrl: request.staticUrl, 
      detailData:''
    }
  },
  mounted(){
    this.getDetail();
  },
  methods:{
    getDetail(){
      let _this = this
      request.get('/news/'+_this.id, {
        }).then(function (res) {
        if (res.status == 200) {
          _this.detailData = res.data
        }
      })
    },
  },
}
</script>

<style scoped>
.detail{
  margin: 100px auto 20px auto;
}
.detail .detail-header{
  text-align: center;
  border-bottom: 2px solid #000;
}
.detail .detail-header .title{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 0 10px;
  height: 8rem;
  box-sizing: border-box;
  font-weight: bolder;
}
.detail .detail-header .date{
  font-size: 14px;
  color: #8a8a8a;
  margin: 30px 0;
}
.detail .detail-content{
  margin: 20px auto;
}
.detail .detail-content p{
  font-size: 18px;
  color: #3d3d3d;
  font-weight: normal;
  line-height: 2;
}
#header .navi_title a{
  color: #000 !important;
}
#header .header-nav .header-nav-logo button{
  background: url("../../assets/img/logo_black.png") !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border: none;
  height: 18px;
  outline: none;
  width: 120px;
}
#header .header-nav .header-change{
  color: #000 !important;
}

</style>
